<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    // 软键盘关闭事件
    // document.body.addEventListener('focusout', () => {
    //   window.scrollTo(0, 0);
    // });
  }
};
</script>


<style>
html,
body,
#app {
  height: 100vh;
}
.buy-info .van-field__label span {
  color: #888888 !important;
}
.buy-info .van-cell__value input {
  text-align: right !important;
}
.buy-dialog {
  border-radius: 0.107rem !important;
  width: 7.2rem;
  height: 6.774rem;
}

.rich-text img {
  width: 100%;
  height: auto;
}

.htmlInfo p {
  font-size: 0.426rem;
}
.htmlInfo h1 {
  font-size: 0.48rem;
}
.play-box .van-slider__button {
  width: 0.3rem;
  height: 0.3rem;
}
</style>
