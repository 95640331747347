import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  // mode:'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('../view/mysj/mainPage'),
      meta: {
        keepAlive: false,
        title: '美云数据'
      }
    },
    {
      path: '/loginCompany',
      name: 'loginCompany',
      component: () => import('../view/login/loginCompany'),
      meta: {
        keepAlive: false,
        title: '登录(企业)'
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../view/dashboard/dashboard'),
      meta: {
        keepAlive: false,
        title: '首页'
      }
    },
    {
      path: '/infoList',
      name: 'infoList',
      component: () => import('../view/mysj/infoList'),
      meta: {
        keepAlive: false,
        title: '美云数据'
      }
    },
    {
      path: '/infoDetail',
      name: 'infoDetail',
      component: () => import('../view/mysj/infoDetail'),
      meta: {
        keepAlive: false,
        title: '美云数据'
      }
    },
  ]
});
router.afterEach(route => {
  // 从路由的元信息中获取 title 属性
  if (route.meta.title) {
    document.title = route.meta.title;
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout(_ => {
        document.body.removeChild(hackIframe)
      }, 300)
    }
  }
});
export default router